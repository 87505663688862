import React from "react"
import PropTypes from "prop-types"
import "../stylesheets/global.scss" // import global styles
import Meta from "./meta"
import Menu from "./menu"
import Footer from "./footer"

const Layout = ({ mainClass, pageName, children }) => {
  return (
    <>
      <Meta lang="en" pageName={pageName} />
      <Menu />
      <main className={mainClass && `main--${mainClass}`}>
        {children}
      </main>
      <Footer />
    </>
  )
}

Layout.defaultProps = {
  pageName: "JCC Senior EXPO 2023",
}

Layout.propTypes = {
  pageName: PropTypes.string,
}

export default Layout
