/* General helpers */
export const slugify = text => {
  return (
    text &&
    text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/&/g, "-and-")
      .replace(/[\s\W-/_]+/g, "-")
  ); // Replace spaces, non-word characters, underscores and dashes with a single dash (-)
}

/* Pages */
export const normalizeHomePage = homePage => ({
  intro: homePage.shortIntroduction,
  longerIntro: homePage.slightlyLongerIntroduction,
  video: homePage.introductoryVideoFor2023,
})

export const normalizeAboutPage = aboutPage => ({
  intro: aboutPage.shortIntroduction,
  description: aboutPage.information.childContentfulRichText.html,
  buildingUpdate: aboutPage.buildingUpdate
})

export const normalizeSponsorBooth = sponsorBooth => ({
  name: sponsorBooth.vendorName,
  logo: sponsorBooth.vendorLogo?.fluid,
  tagline: sponsorBooth.vendorTagline,
  description: sponsorBooth.vendorDescription.childContentfulRichText.html,
  descriptionImage: sponsorBooth.vendorDescriptionImage?.fluid,
  addressLat: sponsorBooth.vendorAddress?.lat,
  addressLon: sponsorBooth.vendorAddress?.lon,
  address: sponsorBooth.vendorStreetAddress,
  city: sponsorBooth.vendorCity,
  state: sponsorBooth.state,
  zip: sponsorBooth.vendorAddressZip,
  addressNotes: sponsorBooth.vendorAddressNotes,
  phone: sponsorBooth.vendorPhoneNumber,
  email: sponsorBooth.vendorEmailAddress,
  website: sponsorBooth.vendorWebsite
})

export const normalizeSponsorGold = sponsorGold => ({
  name: sponsorGold.vendorName,
  logo: sponsorGold.vendorLogo?.fluid,
  tagline: sponsorGold.vendorTagline,
  description: sponsorGold.vendorDescription.childContentfulRichText.html,
  descriptionJson: sponsorGold.vendorDescription.json,
  addressLat: sponsorGold.vendorAddress?.lat,
  addressLon: sponsorGold.vendorAddress?.lon,
  address: sponsorGold.vendorAddressStreet,
  city: sponsorGold.vendorAddressCity,
  state: sponsorGold.vendorAddressState,
  zip: sponsorGold.vendorAddressZip,
  addressNotes: sponsorGold.vendorAddressNotes,
  phone: sponsorGold.vendorPhoneNumber,
  email: sponsorGold.vendorEmailAddress,
  website: sponsorGold.vendorWebsite,
  brochure: sponsorGold.vendorBrochure?.file.url
})

export const normalizeSponsorPremier = sponsorPremier => ({
  name: sponsorPremier.vendorName,
  logo: sponsorPremier.vendorLogo?.fluid,
  tagline: sponsorPremier.vendorTagline,
  video: sponsorPremier.vendorVideo,
  description: sponsorPremier.vendorDescription.childContentfulRichText.html,
  addressLat: sponsorPremier.vendorAddress?.lat,
  addressLon: sponsorPremier.vendorAddress?.lon,
  address: sponsorPremier.vendorAddressStreet,
  city: sponsorPremier.vendorAddressCity,
  state: sponsorPremier.vendorAddressState,
  zip: sponsorPremier.vendorAddressZip,
  addressNotes: sponsorPremier.vendorAddressNotes,
  phone: sponsorPremier.vendorPhoneNumber,
  email: sponsorPremier.vendorEmailAddress,
  website: sponsorPremier.vendorWebsite,
  brochure: sponsorPremier.vendorBrochure?.file.url,
  facebook: sponsorPremier.vendorFacebookLink,
  instagram: sponsorPremier.vendorInstagramLink,
  twitter: sponsorPremier.vendorTwitterLink,
  youtube: sponsorPremier.vendorYouTubeLink
})

export const normalizeSponsorTitle = sponsorTitle => ({
  name: sponsorTitle.vendorName,
  logo: sponsorTitle.vendorLogo?.fluid,
  tagline: sponsorTitle.vendorTagline,
  video: sponsorTitle.vendorVideo,
  description: sponsorTitle.vendorDescription.childContentfulRichText.html,
  addressLat: sponsorTitle.vendorAddress?.lat,
  addressLon: sponsorTitle.vendorAddress?.lon,
  address: sponsorTitle.vendorAddressStreet,
  city: sponsorTitle.vendorAddressCity,
  state: sponsorTitle.vendorAddressState,
  zip: sponsorTitle.vendorAddressZip,
  addressNotes: sponsorTitle.vendorAddressNotes,
  phone: sponsorTitle.vendorPhoneNumber,
  email: sponsorTitle.vendorEmailAddress,
  website: sponsorTitle.vendorWebsite,
  facebook: sponsorTitle.vendorFacebookLink,
  twitter: sponsorTitle.vendorTwitterLink,
})

/* Components */
export const normalizeFooter = footer => ({
  mission: footer.mission,
  phone: footer.contactPhoneNumber,
  email: footer.contactEmailAddress
})
