export const allRoutes = {
  home: '',
  directory: 'directory',
  about: 'about',
  website: 'https://jccdallas.org',
  seniorWebsite: 'https://jccdallas.org/seniors/',
  instagram: 'https://www.instagram.com/jccdallas/',
  facebook: 'https://www.facebook.com/jccdallas',
  twitter: 'https://twitter.com/JCCDallas',
  linkedin: 'https://www.linkedin.com/company/6497369/',
  youtube: 'https://www.youtube.com/channel/UCm9t_AAU5c7I1wo0SJ70nNw/',
  privacy: 'https://jccdallas.org/privacy-policy/',
  terms: 'https://jccdallas.org/terms-conditions/',
  titleSponsor: 'https://thelegacyseniorcommunities.org/'
}

const routeFactory = (name, path, opts = {}) => ({ name, path, ...opts })

export const menuRoutes = [
  routeFactory('Home', allRoutes.home),
  routeFactory('Directory', allRoutes.directory),
  routeFactory('About', allRoutes.about),
]

export const socialRoutes = [
  routeFactory('Website', allRoutes.website),
  routeFactory('Instagram', allRoutes.instagram),
  routeFactory('Facebook', allRoutes.facebook),
  routeFactory('Twitter', allRoutes.twitter),
  routeFactory('LinkedIn', allRoutes.linkedin),
  routeFactory('YouTube', allRoutes.youtube),
]