import React from "react"
import { useStaticQuery, graphql } from "gatsby" // to query for image data
import LinkBtn from "../components/linkBtn"
import { allRoutes, menuRoutes } from "../helpers/routes"
import Img from "gatsby-image"

const Menu = () => {
  const data = useStaticQuery(graphql`
    query {
      expoLogo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      jLogo: file(relativePath: { eq: "j-logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      legacySeniorCommunities: file(relativePath: { eq: "legacy-logo-2023.png" }) {
        childImageSharp {
          fluid(maxWidth: 260) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  
  return (
    <div className="menu-parent">
      <a className="skip-to-content-link" href="#main">
        Skip to content
      </a>
      <nav className="menu-wrapper">
        <div className="menu-wrapper-group">
          <LinkBtn to={allRoutes.home} className="menu-brand__logo">
            <span className="is-visually-hidden">JCC Senior EXPO 2023</span>
            <Img fluid={data.expoLogo.childImageSharp.fluid} alt="JCC Senior EXPO 2023" />
          </LinkBtn>

          <div className="menu">
            <ul className="menu__list">
              {menuRoutes.map((route) => (
                <li key={route.path} className="menu__item">
                  <LinkBtn to={route.path} className="menu__item__link">
                    {route.name}
                  </LinkBtn>
                </li>
              ))}
            </ul>
          </div>

          <aside className="menu-title-sponsor">
            <p className="menu-title-sponsor__text by">brought to you by</p>

            <LinkBtn
              to={allRoutes.website}
              className="menu-title-sponsor__logo j"
            >
              <Img fluid={data.jLogo.childImageSharp.fluid} alt="JCC Dallas logo" />
            </LinkBtn>

            <p className="menu-title-sponsor__text article" aria-hidden="true">
              &
            </p>

            <LinkBtn
              to={allRoutes.titleSponsor}
              className="menu-title-sponsor__logo legacy"
            >
              <Img fluid={data.legacySeniorCommunities.childImageSharp.fluid} alt="The Legacy Senior Communities" />
            </LinkBtn>
          </aside>
        </div>
      </nav>
    </div>
  )
}

export default Menu
