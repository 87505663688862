import React from 'react'
import { Link } from 'gatsby'

const LinkBtn = ({ to, className, download = false, attrs, title, children }) => {
  const isExternalLink = /^(http|mailto|ctfassets)/.test(to)
  const linkMarkup =
    isExternalLink || download ? (
      <a
        href={to}
        target={isExternalLink || download ? '_blank' : '_self'}
        rel={download ? '' : 'noopener noreferrer'}
        className={className}
        download={download ? true : false}
        title={title ? title : ''}
        {...attrs}
      >
        {children}
      </a>
    ) : (
      <Link
        to={`/${to}`}
        className={className}
        activeClassName="active"
        title={title ? title : ''}
        {...attrs}
      >
          {children}
      </Link>
    )

  return linkMarkup
}

export default LinkBtn