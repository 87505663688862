import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import { normalizeFooter } from "../helpers"
import { allRoutes } from "../helpers/routes"
import LinkBtn from "../components/linkBtn"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { 
  SocialFacebook, 
  SocialTwitter, 
  SocialYoutube, 
  SocialLinkedIn,
  SocialInstagram,
  SocialLink
} from "../assets/images"
import Img from "gatsby-image"

const Footer = ({ data }) => {
  const { mission, phone, email } = normalizeFooter(data.allContentfulAboutTheJcc.edges[0].node);
  const [inputEmail, setInputEmail] = useState('');
  const [mailchimpFeedback, setMailchimpFeedback] = useState('');
  const [mailchimpFeedbackType, setMailchimpFeedbackType] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    
    addToMailchimp(inputEmail)
      .then(data => {
        setMailchimpFeedback(data.msg)
        setMailchimpFeedbackType(data.result)
      }
    );
  }

  return (
    <footer>
      <div className="content-area">
        <div className="footer-columns">
          <section className="footer-columns__column">
            <h3 className="footer-columns__column__header">Our Mission</h3>
            <p>{mission}</p>
          </section>

          <section className="footer-columns__column">
            <h3 className="footer-columns__column__header">Join Our Email List</h3>
            <p>Join our email list to receive updates about our events and programs.</p>
            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <input 
                  aria-label="Email address" 
                  placeholder="Email address" 
                  type="email" 
                  value={inputEmail} 
                  onChange={e => setInputEmail(e.target.value)} 
                />
                <button type="submit">Submit</button>
              </div>
              {mailchimpFeedback && (
                <p
                  role="alert"
                  className={`alert alert--${mailchimpFeedbackType}`}
                  dangerouslySetInnerHTML={{ __html: mailchimpFeedback }}
                ></p>
              )}
            </form>
          </section>

          <section className="footer-columns__column contact">
            <h3 className="footer-columns__column__header">Contact Us</h3>
            <p className="contact__intro">Let's connect! Reach out or join us on social media.</p>
            <p className="contact">
              <LinkBtn className="contact__email link--underline" to={`mailto:${email}`}>
                {email}
              </LinkBtn>
              {" "}
              <span className="separator">•</span>{" "}
              <a className="contact contact__phone" href={`tel:${phone}`}>
                {phone}
              </a>
            </p>
            <ul className="social">
              <li key={allRoutes.website}>
                <LinkBtn to={allRoutes.website} title="JCC Dallas website">
                  <SocialLink />
                  <span className="is-visually-hidden">
                    JCC Dallas website
                  </span>
                </LinkBtn>
              </li>

              <li key={allRoutes.facebook}>
                <LinkBtn to={allRoutes.facebook} title="Facebook">
                  <SocialFacebook />
                  <span className="is-visually-hidden">
                    Facebook
                  </span>
                </LinkBtn>
              </li>

              <li key={allRoutes.instagram}>
                <LinkBtn to={allRoutes.instagram} title="Instagram">
                  <SocialInstagram />
                  <span className="is-visually-hidden">
                    Instagram
                  </span>
                </LinkBtn>
              </li>

              <li key={allRoutes.twitter}>
                <LinkBtn to={allRoutes.twitter} title="Twitter">
                  <SocialTwitter />
                  <span className="is-visually-hidden">
                    Twitter
                  </span>
                </LinkBtn>
              </li>

              <li key={allRoutes.youtube}>
                <LinkBtn to={allRoutes.youtube} title="YouTube">
                  <SocialYoutube />
                  <span className="is-visually-hidden">
                    YouTube
                  </span>
                </LinkBtn>
              </li>

              <li key={allRoutes.linkedin}>
                <LinkBtn to={allRoutes.linkedin} title="LinkedIn">
                  <SocialLinkedIn />
                  <span className="is-visually-hidden">
                    LinkedIn
                  </span>
                </LinkBtn>
              </li>

            </ul>
          </section>
        </div>

        <hr />

        <div className="footer-copyright">
          <div className="footer-copyright__logo">
            <LinkBtn to={allRoutes.website}>
              <Img fluid={data.jLogo.childImageSharp.fluid} alt="JCC Dallas logo" />
            </LinkBtn>
          </div>
          <p>
            &copy; Copyright {new Date().getFullYear()} JCC <span className="separator">•</span>{" "}
            <LinkBtn to={allRoutes.privacy} className="link--underline">
              Privacy Policy
            </LinkBtn>{" "}
            <span className="separator">•</span>{" "}
            <LinkBtn to={allRoutes.terms} className="link--underline">
              Terms &amp; Conditions
            </LinkBtn>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default function MyFooter(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulAboutTheJcc {
            edges {
              node {
                mission
                contactPhoneNumber
                contactEmailAddress
              }
            }
          }
          jLogo: file(relativePath: { eq: "j-logo.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      `}
      render={data => <Footer data={data} {...props} />}
    />
  )
}